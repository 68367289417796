import {
	IComponentsStylesOverrides,
	ComponentsStylesOverridesSymbol,
	IPageDidUnmountHandler,
} from '@wix/thunderbolt-symbols'
import { withDependencies } from '@wix/thunderbolt-ioc'

const ResetComponentsStylesOverridesFactory = (
	componentsStylesOverrides: IComponentsStylesOverrides
): IPageDidUnmountHandler => {
	return {
		pageDidUnmount() {
			componentsStylesOverrides.cleanStyles()
		},
	}
}

export const ResetComponentsStylesOverrides = withDependencies(
	[ComponentsStylesOverridesSymbol],
	ResetComponentsStylesOverridesFactory
)
